@import "~antd/dist/antd.less";
@import "./styles/common.less";
@import "./styles/color.less";
@import "./styles/generalStyles.less";
@import "swiper/swiper.less";
@import "swiper/components/navigation/navigation.less";
@import "swiper/components/pagination/pagination.less";
@import "swiper/components/scrollbar/scrollbar.less";
.app-heading {
  margin: 1rem 0;
  font-weight: 600;
}

.app-heading-1 {
  font-size: 32px;
  .app-heading();
  @media (max-width: 600px) {
    font-size: 25px;
  }
}
.search-field {
  margin-right: 1rem;
}

.app-heading-2 {
  font-size: 24px;
  .app-heading();
}

.app-heading-3 {
  font-size: 20px;
  .app-heading();
}

.app-heading-4 {
  font-size: 18px;
  .app-heading();
}

.app-heading-5 {
  font-size: 16px;
  .app-heading();
}

.app-heading-6 {
  font-size: 14px;
  .app-heading();
}

.app-heading-7 {
  font-size: 12px;
  .app-heading();
}

.title-gray {
  margin: 10px 0;
  font-weight: 400;
  color: @color-Manatee;
}

.title-gray-1 {
  font-size: 24px;
  .title-gray();
}
.title-gray-2 {
  font-size: 20px;
  .title-gray();
}

.title-gray-3 {
  font-size: 18px;
  .title-gray();
}
.title-gray-4 {
  font-size: 16px;
  .title-gray();
}
.title-gray-5 {
  font-size: 14px;
  .title-gray();
}
.title-gray-6 {
  font-size: 12px;
  .title-gray();
}
.title-gray-7 {
  font-size: 10px;
  .title-gray();
}

.info-number {
  font-weight: 600;
  color: @color-SharkLite;
  margin: 5px 0;
}
.info-number-1 {
  font-size: 24px;
  .info-number();
}
.info-number-2 {
  font-size: 20px;
  .info-number();
}
.info-number-3 {
  font-size: 18px;
  .info-number();
}

.info-number-4 {
  font-size: 16px;
  .info-number();
}
.info-number-5 {
  font-size: 14px;
  .info-number();
}
.info-number-6 {
  font-size: 12px;
  .info-number();
}
.info-number-7 {
  font-size: 10px;
  .info-number();
}

.app-label-1 {
  color: @color-ManateeLite;
  font-size: 16px;
  font-weight: 600;
}
.app-label-2 {
  color: @color-ManateeLite;
  font-size: 16px;
  font-weight: 500;
}
.app-label-3 {
  color: @color-ManateeLite;
  font-size: 14px;
}
.app-label-4 {
  color: @color-ManateeLite;
  font-size: 12px;
}
.app-label-value-1 {
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0px;
}

.app-input-field-container-1{
  padding: 16px 0px;
}

.app-input-field-container-2{
  padding: 8px 0px;
}

.app-date-picker,
.app-date-picker:hover,
.app-date-picker:active,
.app-date-picker:focus {
  border-bottom: @color-Mariner 2px solid;
}

.app-date-picker,
.app-date-picker:hover,
.app-date-picker:active,
.app-date-picker:focus {
  border-bottom: @color-Mariner 2px solid;
}

.app-input-container {
  margin: 32px 0px;
}

.app-white-card {
  background-color: @color-White;
  padding: 45px;
  border-radius: 10px;
}

.app-white-card-1{
  background-color: @color-White;
  border-radius: 10px;
}

.app-flex-container-1 {
  .display-center-content-space-between();
  margin-bottom: 16px;
}

.app-breadcrum-container {
  width: 95%;
  margin: 0px auto;

  .app-breadcrum {
    font-size: 16px;
    color: @color-Manatee;
    font-weight: 600;

    .app-breadcrum-active {
      color: @color-Mariner;
    }
  }
}

.submit-button,
.submit-button:active,
.submit-button:focus,
.submit-button:hover {
  background: @color-Shamrock 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: none;
  height: 40px;
  color: @color-White;
  margin: 0 20px;
}

.edit-button,
.edit-button:active,
.edit-button:focus,
.edit-button:hover {
  background-color: @color-Serenade;
  border-radius: 5px;
  color: @color-OrangePeel;
  height: 40px;
  border: none;
  margin-right: 10px;
}

.cancel-button,
.cancel-button:active,
.cancel-button:focus,
.cancel-button:hover {
  background-color: @color-White;
  border-radius: 3px;
  border-color: @color-Alto;
  color: @color-ManateeDark;
  height: 40px;
  margin-right: 10px;
}

.ant-input[disabled],
.ant-input-number-disabled {
  background-color: @color-White;
  color: @color-Shark;
}

.general-input-field,
.general-input-field:active,
.general-input-field:hover,
.general-input-field:focus {
  border: none;
  background-color: @color-White;
  border-bottom: 1px solid @color-AthensGray;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  color: @color-Shark;
  font-weight: 600;
  font-size: 15px;
  padding: 12px 2px;
}

.general-input-field-number {
  border: none;
  width: 100%;
  background-color: @color-White;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  color: @color-Shark;
  font-weight: 600;
  font-size: 15px;
}
.ant-input-number-input {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  text-align: left;
  color: @color-Shark;
  font-weight: 600;
  font-size: 15px;
  padding: 12px 2px;
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: @color-Shark;
}

// .ant-select-disabled.ant-select:not(.ant-select-customize-input)
//   .ant-select-selector {
//   background-color: @color-White;
//   color: @color-Shark;
// }

.ant-select:focus,
.ant-select:hover,
.ant-select:active {
  box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: @color-White;
  color: @color-Shark;
  border: none;
  border-bottom: 1px solid @color-AthensGray;
  border-radius: 0px;
  box-shadow: none;
  padding: 0px;
}

.general-select-field,
.general-select-field:hover,
.general-select-field:active,
.general-select-field:focus {
  border: none;
  box-shadow: none;
  text-align: left;
  color: @color-Shark;
  font-weight: 600;
  font-size: 15px;
  padding: 12px 0px;
}

.ant-picker-input {
  .input[disabled] {
    color: @color-Shark;
  }
}

.ant-picker,
.ant-picker-large,
.ant-picker.ant-picker-disabled {
  background-color: @color-White;
  color: @color-Shark;
  border: none;
  border-bottom: 1px solid @color-AthensGray;
  border-radius: 0px;
  box-shadow: none;
  padding: 12px 0px;
}

.ant-picker-input-placeholder .input {
  color: @color-Shark;
}

.border-buttom {
  border-bottom: 2px solid @color-WildSand;
}

.input-field {
  padding: 1rem;
  border-radius: 3px;
  width: 100%;
  margin : 0.5rem 0;
}

::-webkit-scrollbar {
  width: 0.5px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: @color-SeashellSolid;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: @color-Gray;
  opacity: 1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: @color-Emperor;
  opacity: 1;
}

.skip-button {
  height: 40px;
  letter-spacing: 0px;
  color: @color-Black;
  margin-left: 20px;
}

.full-width {
  width: 100%;
}

.full-min-height {
  min-height: 100%;
}

* {
  scrollbar-width: none;
}

.app-main-content-container {
  width: 95%;
  border-radius: 10px;
  margin: 0px auto;
  margin-top: 25px;
}

.document-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-display {
  width: 100%;
  height: auto;
}

.pdf-document-action {
  display: flex;
  align-items: center;
  justify-content: space-around;
  p {
    font-weight: 500;
  }
}
.ctc-input {
  background-color: @color-WildSand;
  padding: 12px;
  margin-bottom: 36px;
  border: none;
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
}

.employee-input {
  background: transparent;
  padding: 12px;
  margin-bottom: 24px;
  border: none;
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
  border-bottom: 0.1px solid @color-Manatee;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: @color-Blue;
  font-size: 14px;
  font-weight: 500;
}

.ant-menu-item a,
.ant-menu-item a:hover {
  font-size: 14px;
  font-weight: 500;
}

.ant-menu-item {
  width: 200;
}
.ant-picker-input > input{
  font-weight: 600;
}

.general-ant-field-label {
  margin-top: 16px;
  color: #9898a5;
  font-size: 16px;
}

.general-ant-field-input {
  font-weight: 500;
  padding: 6px 0px;
  background-color: @color-White;
  border-radius: 0px;
  text-align: left;
  color: @color-Shark;
  margin-bottom: 10px;
  border: none;
  box-shadow: none;
  border-bottom: 0.1px solid #eaeaee;
  font-size: 16px;
}


.general-ant-field-input:active,
.general-ant-field-input:hover,
.general-ant-field-input:focus {
  border-bottom: 2px solid #405cd2;
}

.general-ant-field-select {
  font-weight: 500;
  padding: 0px 2px;
  margin-bottom: 10px;
  background-color: @color-White;
  border-radius: 0px;
  text-align: left;
  color: @color-Shark;
  border: none;
  box-shadow: none;
  border-bottom: 0.1px solid #eaeaee;
  font-size: 16px;
}

.general-ant-field-select:active,
.general-ant-field-select:focus,
.general-ant-field-select:hover {
  border-bottom: 2px solid #405cd2;
}
.org-data-container {
  .fields-heading {
    margin-top: 16px;
    font-size: 1rem;
    color: @color-ManateeLite;
  }

  .input-field {
    border-radius: 0.2rem;
    outline: none;
    height: 50px;
  }
  .phone-number-input {
    width: 65%;
    display: inline-block;
    .county-code-select {
      width: 15%;
      height: 100%;
      display: inline-block;
      margin-right: 5%;
    }
  }
}

.select-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.individual-structure {
  .display-center-content-space-between();
  width: 100%;
  height: 50px;
  padding: 10px 0;

  .break-label {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1.19;
    text-align: left;
    color: @color-MidGray;
  }

  .break-value {
    font-size: 16px;
    line-height: 1.19;
    text-align: left;
    color: @color-MidGray;
  }

  .break-label-earning {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    text-transform: capitalize;
    text-align: left;
    color: @color-Shamrock;
  }

  .break-value-earning {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: @color-Shamrock;
  }
  .break-label-deduction {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: @color-Froly;
  }

  .break-value-deduction {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: @color-Froly;
  }
}
.takehome {
  color: @color-Denim;
}
.earning {
  color: @color-Shamrock;
}
.deduction {
  color: @color-Froly;
}
.individual-structure-takehome {
  .display-center-content-space-between();
  width: 100%;
  height: 50px;
  padding: 10px 0;

  .break-label {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: @color-Denim;
  }

  .break-value {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.19;
    text-align: left;
    color: @color-Denim;
  }
}

.uploaded-docs-card {
  padding: 12px;
  margin: 8px 0px;
  border-radius: 10px;
  .display-center-content-space-between();
  background-color: @color-White;

  .uploaded-docs-left {
    .uploaded-docs-card-name {
      display: inline-block;
      margin-left: 8px;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.employee-details-container {
  background-color: @color-White;
  padding: 1.5rem;
  border-radius: 10px;
  height: 100%;
}

.paper-wrapper {
  background-color: @color-White;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;

  .paper-header-container {
    .display-center-content-space-between();

    .paper-heading {
      font-size: 25px;
      font-weight: 600;
    }
    .paper-heading-action {
      .display-center-content-space-between();
    }
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.flex {
  display: flex;
}

.block{
  display: block;
}

.inline-block{
  display: inline-block;
}

.inline{
  display: inline;
}

.flex-direction-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.text-align-center {
  text-align: center;
}

.ant-table-thead > tr > th{
  color: #bbbbbf;
  background: none;
}

.success-text{
  color : @color-Java;
}

.danger-text{
  color : @color-Froly;
}

.warning-text{
  color : @color-FuelYellow;
}

.info-text{
  color: @color-Denim;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #405cd2;
  border: none;
}


.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 0px solid #1DA57A;
}



.header-menu-slab {
  width: 16rem;
  // padding: 1.5rem;
  border-radius: 10px;
  flex-direction: column;
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    transition: all 0s ease;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #4d6dee;
    color: white;
  }
  .menu-actions {
    .action-item {
      padding: 0.6rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: #f7f8f8;
      }

      .menu-text {
        color: #3c4043;
        letter-spacing: 0.25px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
  .header-menu-container {
    flex-direction: column;
    gap: 0.3rem;
    .image-container {
      width: 7rem;
      height: 7rem;
      margin: 0 auto;
      border-radius: 50%;
      background-color: #f0f2f5;
      margin-top: 1.5rem;
    }
    .header-menu-logo {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: scale-down;
    }
    .header-menu-displayName {
      font-weight: 600;
      color: #151b30;
      text-align: center;
      font-size: 0.8rem;
      padding: 5px;
    }

    .header-menu-email {
      font-weight: 300;
      color: #90919b;
      font-size: 0.65rem;
      letter-spacing: 0.4px;
    }

    .header-menu-PAN {
      font-weight: 400;
      color: #90919b;
      font-size: 0.7rem;
    }
  }
}

.header-logo {
  padding: 1.5rem;
  width: 10rem;
  height: 10rem;
}
.header-vertical-line {
  height: 4rem;
  width: 1px;
  background-color: #d5d6df;
}

.profile-header {
  border-bottom: 1px solid #d5d6df;
  width: 100% !important;
  height: 65px !important;
}
.header-label-1 {
  font-size: 1rem;
}

.header-link,
.header-link:visited,
.header-link:active {
  color: #000;
}

.profile-heading {
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.header-menu-line {
  height: 0.05rem;
  width: 100%;
  margin-top: 1rem;
  background-color: #eaeaee;
}
@primary-color: #1DA57A;