.input-field-container{
   padding-top: 8px;
   border: none;
   background-color: #fff;
   border-bottom: #eaeaee 2px solid;
   width: 100%;
   cursor: text;
   outline: none;
   box-shadow: none;
   font-size: 16px;
   color: #232326;
   font-weight: 500;
   border-radius: 2px;
   transition: all 0.3s;
}

.input-field-container:hover:enabled,
.input-field-container:active:enabled,
.input-field-container:focus:enabled{
    border-bottom: #405cd2 2px solid;
}


.input-field-container:active:enabled,
.input-field-container:focus:enabled{
    box-shadow: 0px 2.5px 1px #c8cee7;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@primary-color: #1DA57A;