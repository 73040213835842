.dashboard-headings-1 {
  color: #90919b;
  font-size: 22px;
}

.stats-cards-container {
  display: flex;
  width: 100%;

  .stat-card-container {
    display: flex;
    align-items: center;

    .stat-card-logo-container {
      width: 90px;
      min-height: 90px;
      border-radius: 50px;
      margin-right: 24px;
    }

    .stat-card-text-container {
      .stat-card-card-value {
        font-size: 32px;
        font-weight: 900;
      }
    }
  }
}

.right-pane-container {
  height: 100%;
  .dashboard-current-year {
    font-size: 40px;
    font-weight: 900;
  }

  .dashboard-hr {
    margin: 32px 0px;
  }

  .org-card-container {
    max-height: 500px;
    overflow-y: scroll;
    margin: 8px 0px 16px 0px;
    .org-card {
      display: flex;
      align-items: center;
      margin: 12px 0px;
      .org-card-img-container {
        margin-right: 24px;

        .org-card-img {
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }
      }
      .org-card-text-container {
        .org-card-text-org-name {
          font-size: 16px;
          font-weight: 600;
        }
        .org-card-text-pan {
          font-size: 14px;
          font-weight: 600;
          color: #93979b;
        }
      }
    }
  }
}

.charts-cards-container {
  width: 100%;
  .chart-card {
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

.card-data-heading {
  font-size: 20px;
  color: #90919b;
  font-weight: 400;
}

.number-card-container {
  margin-bottom: 48px;
  .number-card {
    background-color: #fff;
    height: 125px;
    border-radius: 10px;
    padding: 24px;
    display: flex;
    align-items: center;

    .card-logo {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card-data-container {
      .card-data {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}

.charts-card {
  background-color: #fff;
  height: 35rem;
  border-radius: 10px;
  padding: 24px;

  .card-data-heading {
    margin-bottom: 16px;
  }

  .amount-and-option-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .currency-format {
      font-size: 16px;
      color: #90919b;
      font-weight: 400;
      margin-right: 16px;
    }

    .chart-amount {
      font-size: 48px;
      font-weight: 700;
    }
  }
}

.ctc-calculator-container {
  background-color: #fff;
  padding: 24px;
  width: 100%;
  border-radius: 10px;
  height: 100%;

  .ctc-calculator-heading {
    font-size: 18px;
    font-weight: 700;
  }

  .financial-year {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .ctc-heading-container {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    margin-bottom: 36px;

    .download-slip-button {
      background-color: #fff1de;
      color: #fea101;
      border-radius: 10px;
      border: none;
    }
  }

  hr {
    color: #e8e9ee;
  }

  .ctc-items-container {
    color: #5f5f65;
    font-size: 16px;
    font-weight: 500;

    .ctc-item {
      display: inline-block;
      margin-bottom: 24px;
      width: 70%;
    }

    .ctc-item-value {
      display: inline-block;
      width: 30%;
      text-align: right;
    }
  }
}

.ctc-calc {
  height: 100%;
  background-color: #f7f7fa;
  border-radius: 7px;
  padding: 21px 15.5px 11px 21px;
  overflow-y: scroll;

  h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: #2d2e30;
    margin: 20px 0;
  }
  .break-down-value {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@primary-color: #1DA57A;