.logo {
  height: 50px;
  position: sticky;
  padding: 0 24px;
  top: 0;
  z-index: 50;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .mool-logo {
    height: auto;
    width: 6rem;
  }

  .trigger {
    padding: 8px 30px;
    font-size: 15px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #73737b;
  }
}

.display-container {
  min-height: 100vh;
  width: 100%;

  .sider {
    position: sticky;
    background: #151b30;
    left: 0;
    top: 75px;
    z-index: 50;
  }

  .site-layout {
    .site-layout-header {
      background: #f0f2f5;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 95%;
      padding: 0;
      margin: 0px auto;

      .header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .logout-form {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-around;
        .name-container {
          margin: 0 10px;
          @media (max-width: 600px) {
            display: none;
          }
        }
      }
    }
  }
}

.sidebar-icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

aside.ant-layout-sider.ant-layout-sider-light {
  min-width: 16rem !important;
  max-width: 16rem !important;
  width: 16rem !important;
  position: sticky;
}

ul.ant-menu.ant-menu-light.sider.ant-menu-root.ant-menu-inline {
  color: white;
  padding-top: 4rem;
  li {
    margin-bottom: 0.8rem;
  }
}

.ant-menu-item a {
  color: #aeafb8;
  font-size: 0.9rem;
  &:hover {
    font-size: 0.9rem;
  }
}

.logo .mool-logo {
  margin-top: 3rem;
  width: 7rem;
}

element.style {
  height: 50 px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24 px;
  background-color: red;
  width: 80%;
  margin: 0 auto;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #405cd2;
  border: none;
  a {
    color: #fff !important;
  }
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px solid transparent;
}

.ant-menu-inline {
  width: calc(100% + 1px);
}

li.ant-menu-item.ant-menu-item-selected {
  width: 100%;
}

.ant-menu-item-selected a:hover {
  color: white;
}

.first-load {
  background-color: #405cd2;
  a {
    color: white;
  }
}

.ant-menu-item a:hover {
  color: #fff;
}

.ant-table-tbody > tr > td {
  font-weight: 500;
}

@primary-color: #1DA57A;