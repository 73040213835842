

.support-container {
  width: 95%;
  border-radius: 10px;
  margin: 0px auto;
  margin-top: 25px;

  .table-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;

    .table-container-heading {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
}
.form-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: auto;
  border-radius: 10px;

  .form-heading {
    margin-bottom: 15px;
  }
  .form-data-container {
    margin-bottom: 15px;

    .phone-number-holder {
      .phone-country-code {
        color: #232326;
        font-weight: 700;
        font-size: 17px;
        padding: 15px 2px;
      }
      .phone-number {
        margin-left: 10px;
        color: #232326;
        font-weight: 700;
        font-size: 17px;
        padding: 15px 2px;
      }
    }
  }

  .form-type {
    display: block;
  }

  .form-labels {
    font-weight: 500;
    font-size: 16px;
    color: #9898a5;
    display: block;
    margin-bottom: 8px;
  }
}

@primary-color: #1DA57A;