.first-login-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../assets/register_background.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    @media (max-width: 450px) {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .stage-container {
        max-width: 680px;
        width: 40%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 60px #9191913F;
        border-radius: 15px;
        padding: 25px 25px 10px;

        @media (max-width: 1200px) {
            width: 50%;
            padding: 35px
        }

        @media (max-width: 1100px) {
            width: 60%;
            padding: 35px
        }

        @media (max-width: 650px) {
            width: 80%;
            padding: 30px
        }

        @media (max-width: 450px) {
            width: 100%;
            padding: 20px
        }

        .stage-heading {
            
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.22;
            letter-spacing: normal;
            text-align: left;
            color: #232326;
        }

        .stages {
            width: 100%;
            padding: 10px 5px;
            display: flex;
            justify-content: space-between;
            align-self: flex-start;
            flex-direction: column;
            .password-container {
                min-height: 25rem;
                
            }
            .main-container {
                .ant-upload-drag-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .ant-upload-text {
                        margin: 0 0 4px;
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 16px;
                    }

                    .ant-upload-drag-icon {
                        color: #405CD2;
                    }

                    .ant-upload-hint {
                        color: rgba(0, 0, 0, 0.45);
                        font-size: 14px;
                        text-align: center;
                        line-height: 15px;
                        font-weight: 300;

                    }
                }

                p {
                    
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1.83;
                    text-align: left;
                    color: #232326;
                    margin: 0;
                }

                .register-input {
                    width: 100%;
                    border-bottom: 0.5px solid #EAEAEE;
                    height: 50px;
                }

                .radio-group {
                    width: 50%;
                    margin: 25px 0;

                    p {
                        text-align: left;
                        font-size: 15px;
                        letter-spacing: 0px;
                        color: #232326;
                        margin-bottom: 5px;
                    }
                }

                .header-display {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .search-feild {
                        width: 250px;
                        background-color: #f5f5f5;
                    }
                }

                .allowances-container {
                    max-height: 340px;
                    height: 340px;
                    overflow-y: scroll;
                    scrollbar-width: none; 
                    width: 100%;

                    .single-allowance {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: none;
                        border-bottom: solid 0.1px #eaeaee;
                        padding: 5px;
                        height: 50px;

                        p {
                            
                            font-size: 15px;
                            font-weight: normal;
                            text-align: left;
                            color: #9898a5;
                            margin: 0;
                        }
                    }

                    .Employee-Upload-Status {
                        width:  100%;
                        p {
                            
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 1.83;
                            text-align: left;
                            color: #232326;
                            margin: 0;
                        }

                        .response-data {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 10px;

                            p {
                                
                                font-size: 15px;
                                font-weight: 400;
                            }
                        }
                        .success {
                            p {
                                color: green;
                                font-weight: 400;
                            }
                            
                        }
                        .fail {
                            p {
                                color: red;
                                font-weight: 400;
                            }
                            
                        }
                    }
                }

            }

            .submit-container-password {
                margin: 10px 0;
                display: flex;
                justify-content: flex-end;

                .submit-button {
                    background: #40D2AC 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    border: none;
                    height: 40px;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                }
            }

            .submit-container {
                margin: 10px 0;
                display: flex;
                justify-content: flex-end;

                .skip-button {
                    background: #fff 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    height: 40px;
                    letter-spacing: 0px;
                    color: #000;
                    margin-left: 20px;
                }

                .submit-button {
                    background: #40D2AC 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    border: none;
                    height: 40px;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    margin-left: 20px;
                }
            }
        }

    }
}

/* width */
::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
@primary-color: #1DA57A;