h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}
.display-align-center {
  display: flex;
  align-items: center;
}
.display-content-center {
  display: flex;
  justify-content: center;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
}
.display-center-content-space-between {
  .display-align-center();
  justify-content: space-between;
}
.display-justify-content-space-between {
  display: flex;
  justify-content: space-between;
}
.display-flex-direction-column-align-items-flex-start {
  .display-flex-column();
  align-items: flex-start;
}
.display-flex-align-items-center-justify-content-flex-end {
  .display-align-center();
  justify-content: flex-end;
}
.display-align-center-justify-content-center {
  .display-align-center();
  justify-content: center;
}
.display-flex-column-justify-content-space-around {
  .display-flex-column();
  justify-content: space-around;
}
.display-flex-column-justify-content-center {
  .display-flex-column();
  justify-content: center;
}
.display-align-center-justify-content-space-around {
  .display-align-center();
  justify-content: space-around;
}
.display-flex-justify-content-flex-end {
  display: flex;
  justify-content: flex-end;
}
.display-align-center-justify-center-flex-column {
  .display-align-center-justify-content-center();
  flex-direction: column;
}
.display-align-center-flex-column {
  .display-align-center();
  flex-direction: column;
}

.font-style-left {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}
.font-style-right {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
}

.border {
  border: none;
  box-shadow: none;
}

.img-background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.header-component {
  .display-justify-content-space-between();
  align-items: center;
  margin-bottom: 10px;

  .header-link {
    color: #fea101;

    img {
      height: 15px;
      width: 15px;
      margin-right: 2px;
    }
  }
}

.ads-container{
  height: 8rem;
  width: 100%;
  margin: 20px 0px;
  border: 1px solid transparent;
  padding: 5px;
  .photo-holder{
    overflow:hidden;
    object-fit: cover;
    border-radius: 10px;
  }
}