.organisation-setting-container {
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 10px;
  height: 100%;

  .pane-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .photo {
    
  }
  .submit-button-container {
    justify-self: end;
  }
  .main-body-container-2 {
    width: 30%;
  }
}

.employee-profile-container {
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 10px;
  height: 50%;

  .pane-heading {
    padding: 0px 0px 1rem 0px;
    font-size: 1.3em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .employee-basic-info {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .employee-logo-container {
      margin-right: 2rem;

      .employee-logo {
        background-color: #fea101;
        border-radius: 50%;
      }
    }

    .employee-name-container {
      margin-right: 2rem;
    }

    .active-button,
    .active-button:hover {
      border: none;
      background-color: #e4fff8;
      color: #15cbac;
      border-radius: 0.5rem;
    }
  }
}

.update-img-modal-header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;

  .modal-heading {
    font-size: 18px;
    font-weight: 700;
  }
}

.update-img-modal-body {
  padding: 24px 0;

  .update-img-modal-text{
    display: flex;
    flex-direction: column;
  }
}

.avatar-update.ant-upload.ant-upload-select-picture-card{
  width: 500px;
}

.company-data{

  .company-data-cards{
    height: 100%;
    .company-data-card-company-about{
      margin: 32px 0px;
    }

    .company-data-company-code{
      font-size: 48px;
      font-weight: 900;
      margin: 32px 0px;
    }
    .company-data-cards-info{
      color: #40d2ac;
    }
  }
}

.company-address-container{
  padding: 42px 48px;
  margin-bottom: 20px;
  height: 100%;
  
  .company-address-individual-data-container{
    margin: 32px 0px;
  }
}

.photo-update-container {
  display: flex;
  align-items: center;

  .current-logo-holder {
    margin-top: 16px;
    margin-right: 2rem;
    width: 60px;
    height: 60px;

    .ant-modal-body {
      display: flex;
      justify-content: center;

      .modal-cancel-btn {
      }
      .avatar-update {
        display: block;
        width: 500px;
      }
    }

    .upload-container {
      display: flex;
      justify-self: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 0;
    }
    .current-logo {
      height: 100%;
    }
  }

  .change-button {
    background-color: #fff7ec;
    border-radius: 4px;
    border: none;
    color: #fea101;
  }
}

.organisation-data-container{
    margin-bottom: 20px;
    height: 100%;

    .organisation-data-heading-container{
      margin-bottom: 32px;
      .organisation-name-logo-container{
        display: flex;
        align-items: center;
  
        .current-logo-holder-has-logo {
          display: flex;
          justify-content: space-between;
          align-items: center;
        
          .default-logo {
            background-color: #fff1de;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 24px;
          }
        
          .current-logoo {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 24px;
            object-fit: contain;
          }
        }
      }
    }
}

@primary-color: #1DA57A;